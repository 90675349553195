import { PersistedDataKeys } from '@lib/core';

import { Maybe } from '__generated__/types';
import { client } from 'common/graphql/apollo';
import { persistedData } from 'common/helpers/persistedData';
import {
  MngRefreshSession,
  MngRefreshSessionDocument,
  MngRefreshSessionVariables,
} from 'features/Auth/graphql/__generated__/MngRefreshSession.mutation';

export const refreshSession = async (currentRefreshToken?: Maybe<string>): Promise<void> => {
  persistedData.clear();

  if (currentRefreshToken) {
    try {
      const { data, errors } = await client.mutate<MngRefreshSession, MngRefreshSessionVariables>({
        variables: {
          refreshToken: currentRefreshToken,
        },
        mutation: MngRefreshSessionDocument,
      });

      if (errors) {
        // TODO: Implement notifications

        return;
      }

      if (data) {
        const { expiresIn, accessToken } = data.mngRefreshSession;

        persistedData.setAccessToken({ accessToken, expiresAtSeconds: expiresIn });
        persistedData.set(PersistedDataKeys.RefreshToken, currentRefreshToken);
      }
    } catch (error) {
      // TODO: Implement notifications
    }
  }
};
